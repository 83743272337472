import React from 'react'
import Layout from "../components/layout"
import insta from '../images/claudio-insta-1.png'
import SEO from '../components/seo'

export default function Article() {
    return (
        <Layout>
            <SEO title="Claudio, the Wounded Soldier" image={insta} />
            <h1>
            Claudio, the Wounded Soldier
            </h1>
            <img src={insta} />
            <p>Wondering how Claudio is taking the news about Hero? In case you missed it, here is his post:</p>

            <p>“Hero is NOT the woman I thought she was. She is not the woman YOU thought she was. She said she LOVED me. We even talked about MARRIAGE. But none of that stopped her from being with ANOTHER MAN later that same night! 
            And based on the looks of the man in the image, ANY MAN will do…
            Who knows how many there are or how many there have been! A word of warning: stay away from her…she may be pretty, but she has a CRUELTY and UGLINESS inside her!”</p>

            <p>Ouch! We’re here for you, Claudio.</p> 
        </Layout>
    )
}
